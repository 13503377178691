Container and General Styling
.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    margin-top: -30px;
}

.page-title {
    text-align: center;
    font-size: clamp(1.5rem, 2vw + 1rem, 2rem); /* Responsive font size */
    margin-bottom: 20px;
}

.description {
    font-size: 1.2rem;
    margin-bottom: 40px;
}

/* Table Section Styling */
.table-section {
    margin-bottom: 50px;
}

.section-title {
    font-size: clamp(1.5rem, 2vw + 1rem, 2rem); /* Responsive font size */
    margin-bottom: 20px;
}


.table-container {
    overflow-x: auto; /* Enables scrolling on smaller screens */
}

.responsive-table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ddd;
}

.responsive-table th, .responsive-table td {
    padding: 12px 15px;
    border: 1px solid #ddd;
    text-align: left;
    word-wrap: break-word; /* Allows text to wrap */
}

.responsive-table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.responsive-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.responsive-table tr:hover {
    background-color: #f1f1f1;
}

.section-heading {
    background-color: #e0e0e0;
    font-weight: bold;
    padding: 12px;
    text-align: left;
    font-size: 1.2rem;
}

/* Action Buttons */
.action-button {
 
    background-color: #007bff;
    color: white;
    /* padding: 10px 20px; */
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    /* margin: 4px 2px; */
    transition-duration: 0.4s;

    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.action-button:hover {
    background-color: #0056b3;
}

/* Resource Links */
.resource-link {
    color: #007bff;
    text-decoration: none;
}

.resource-link:hover {
    text-decoration: underline;
}
.social-media-icons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}

.icon {
    font-size: 24px;
    color: #333;
    transition: transform 0.3s, color 0.3s;
}

.icon:hover {
    transform: scale(1.2);
}

.facebook:hover {
    color: #3b5998;
}

.twitter:hover {
    color: #1da1f2;
}

.instagram:hover {
    color: #e4405f;
}


/* Ellipsis for long text on screens below 512px */
@media (max-width: 512px) {
    .ellipsis {
        overflow: hidden;         /* Hides overflowed content */
        white-space: nowrap;      /* Prevents text from wrapping */
        text-overflow: ellipsis;  /* Displays ellipsis */
        max-width: 200px;         /* Sets a max width for the cell */
    }
}

/* Media Query for responsiveness */
@media (max-width: 768px) {
    .responsive-table th, .responsive-table td {
        padding: 10px 5px;
    }

    .page-title {
        font-size: 2rem;  /* Smaller title on mobile */
    }

    .description {
        font-size: 1rem;  /* Smaller description on mobile */
    }
}
