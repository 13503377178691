* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.navbar_admin{ 
    height: 40px ;
    width: 100%;
    border: 1px solid #193950;
    background-color: #193950;
    color: white; 
    display: flex;
    justify-content: space-around;
}
section {
    width: 100%;
    display: flex;
    position: relative;

}

section .imgBx {
    width: 50%;
    height: 100%;
    position: relative;
}

.imgBx img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

section .contentBx {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

section .contentBx .page {
    width: 50%;
}

.center {
    align-items: center;
}

section .contentBx .page h2 {
    font-size: 1.5em;
    color: #0d0404;
    margin-bottom: 20px;
    font-weight: 600;
    display: inline-block;
    letter-spacing: 1px;
    border-bottom: 4px rgb(36, 34, 34) solid;
}

section .contentBx .page .inputBx {
    margin-bottom: 20px;

}

section .contentBx .page .inputBx label {
    font-size: 18px;
    color: #7a7a7a;
    margin-bottom: 5px;
    display: inline-block;
    font-weight: 300;

}

section .contentBx .page .inputBx input {
    width: 100%;
    padding: 10px 20px;
    border: 2px solid #607d8b;
    outline: none;
    font-weight: 400;
    font-size: 15px;
    background: transparent;
    letter-spacing: 1px;
    border-radius: 30px;
}

section .contentBx .page .inputBx input[type="submit"] {
    cursor: pointer;
    background: blueviolet;

}

section .contentBx .page .inputBx input[type="submit"]:hover {
    background: darkblue;
}

@media (max-width: 770px) {
    section .imgBx {
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        position: absolute;
    }

    section .contentBx {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        z-index: 1;
    }

    section .contentBx .page {
        width: 100%;
        padding: 40px;
        background: rgb(255 255 255 / 0.9);
        margin: 50px;
    }

}


.login_bg{ 
    border: 1px solid #193950;
    width: 30rem;
    height: 30rem;
    align-items: center;
    justify-content: center;    
    margin-left: 20rem;
    background-color: rgba(128, 128, 128, 0.049);
  display: flex;
  margin-top: 8rem;
  justify-content: center;
  }
  .email_email{ 
    width: 300px;
    height: 40px;
    border-radius: 4px;
    margin: 10px;
    border: 1px solid #193950;
  } 
  
  .passwardwer{ 
    width: 300px;
    height: 40px;
    border-radius: 4px;
    margin: 10px;
    border: 1px solid #193950;
  }
  .login_button{ 
    width: 300px;
    height: 40px;
    border-radius: 4px;
    color: white;
    margin: 10px;
    background-color:#47a7ed ;
  } 
  .MuiDataGrid-row { 
    background: #94c594;
    border: 1px solid black;
  
  } 
  .kmsview{ 
    background-color: #193950;
  } 