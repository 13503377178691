
body{
  font:  exo, ubuntu, "segoe ui", helvetica, arial, sans-serif;
  /* text-align: center; */
  /* margin: 0; */
  /* z-index: -10; */
}

/* animated background for  */

.upper_header{
  padding: 1rem;
}



.women-logo{
  width: 60%;
}

.image-card {
  position: relative;
  width: 350px;
  height: 550px;
  margin: 30px 0px;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s ease;

  display: flex;
  justify-content: center;
  align-items: center;

  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);

  background-color: #63A9EA;

}

.image {
  width: 100%;
  height: 100%;
  transition: filter 0.3s ease;
}

.image.blur {
  filter: blur(5px);
}

.hover-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 10px;
}

@media (max-width: 600px) {
  .image-card {
    width: 100%;
    height: auto;
  }

  .image {
    height: auto;
  }

  .hover-text {
    /* position: static;  */
    transform: none;
    position: static;
    background-color: transparent;
    color: black;
    font-size: 30px;
    margin-top: 10px;
    padding: 0;
  }

  .image.blur {
    filter: none;
  }
}



/* 
@import url(https://fonts.googleapis.com/css?family=Exo:100);

@-webkit-keyframes bg-scrolling-reverse {
  100% {
    background-position: 50px 50px;
  }
}
@-moz-keyframes bg-scrolling-reverse {
  100% {
    background-position: 50px 50px;
  }
}
@-o-keyframes bg-scrolling-reverse {
  100% {
    background-position: 50px 50px;
  }
}
@keyframes bg-scrolling-reverse {
  100% {
    background-position: 50px 50px;
  }
}
@-webkit-keyframes bg-scrolling {
  0% {
    background-position: 50px 50px;
  }
}
@-moz-keyframes bg-scrolling {
  0% {
    background-position: 50px 50px;
  }
}
@-o-keyframes bg-scrolling {
  0% {
    background-position: 50px 50px;
  }
}
@keyframes bg-scrolling {
  0% {
    background-position: 50px 50px;
  }
}

body {
  color: #999;
  font:  exo, ubuntu, "segoe ui", helvetica, arial, sans-serif;
  text-align: center;
  
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAIAAACRXR/mAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAABnSURBVHja7M5RDYAwDEXRDgmvEocnlrQS2SwUFST9uEfBGWs9c97nbGtDcquqiKhOImLs/UpuzVzWEi1atGjRokWLFi1atGjRokWLFi1atGjRokWLFi1af7Ukz8xWp8z8AAAA//8DAJ4LoEAAlL1nAAAAAElFTkSuQmCC") repeat 0 0;
  -webkit-animation: bg-scrolling-reverse 1.3s  infinite;

  -moz-animation: bg-scrolling-reverse 1.3s  linear infinite;

  -o-animation: bg-scrolling-reverse 1.3s  linear infinite;

  animation: bg-scrolling-reverse 1.3s linear infinite;

  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -o-animation-timing-function: linear;
  animation-timing-function: linear;
}  */

/* animated background ends here */







/* #Site Styles
================================================== */

/*@import url(//fonts.googleapis.com/css?family=Roboto:400,300,500);*/

@import url("https://fonts.googleapis.com/css2?family=Puritan:wght@400;700&display=swap");

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');




.loader {
  border: 16px solid #f3f3f3; 
  border-top: 16px solid #072535; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
  margin: 0 auto; /* Center the loader */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.news_input{
  display: flex;
  margin-top: -1rem;
  margin-bottom: 2rem;
}

/* 
html,body{
scroll-behavior: smooth;
scroll-behavior: smooth !important;


} */
/* *, html {
  scroll-behavior: smooth !important;
} */
/* 
.bg {
  animation:slide 3s ease-in-out infinite alternate;
  background-image: linear-gradient(-60deg, #6c3 50%, #09f 50%);
  bottom:0;
  left:-50%;
  opacity:.5;
  position:fixed;
  right:-50%;
  top:0;
  z-index:-150;
}

.bg2 {
  animation-direction:alternate-reverse;
  animation-duration:4s;
}

.bg3 {
  animation-duration:5s;
}

.content {
  background-color:rgba(255,255,255,.8);
  border-radius:.25em;
  box-shadow:0 0 .25em rgba(0,0,0,.25);
  box-sizing:border-box;
  left:50%;
  padding:10vmin;
  position:fixed;
  text-align:center;
  top:50%;
  transform:translate(-50%, -50%);
}

h1 {
  font-family:monospace;
}

@keyframes slide {
  0% {
      transform:translateX(-25%);
  }

  100% {
      transform:translateX(25%);
  }
} */


/* gradient background */
.super-background{
  background: rgb(251,198,63);
background: radial-gradient(circle, rgba(251,198,63,1) 0%, rgba(70,242,252,0.5971638655462185) 100%);
/* margin: 0%; */
padding: 0% 0%;
}
.scroll{
  scroll-behavior: smooth !important;
}
.login-test{
  display: flex;
  /* justify-content: center; */
  align-items: center;
  /* flex-direction: column; */
  margin: 2rem 1rem;
  /* background: #fff; */


}
.signup_class{
  display: flex;
  /* justify-content: ; */

  align-items: center;
  justify-content: space-around;
  gap: 1rem;
}

.test-data{
  margin: 2rem 1rem;
  background: #fff;
  border-radius: 10px;
  padding: 2rem 2rem;



}

.news_letter p{
  font-size: 1rem;

}

.test-titles h1{
  font-size: 1.5rem;

}
.test-titles h2{
  font-size: 2rem;
}
.home_images{
  width: 40%;
}
/* .naviBox{
  position: -webkit-sticky;
  position: sticky;
  top: 0;
} */
.about_font{
  font-size: 1.5rem;
}



.test_all_itemjs{
  margin: 2rem;
}
.ccctr{ 
  text-align: center;
}


.sub-btn:hover{
  color: rgb(1, 1, 1);
  background-color: transparent;
 
  -webkit-box-shadow: 10px 10px 10px #082132;
  box-shadow: 10px 10px 10px #082132;
  transition: all ease .3s;
  border: 2px solid black;
}
.sub-btn1:hover{
  color: rgb(1, 1, 1);
  background-color: transparent;
 
  -webkit-box-shadow: 10px 10px 10px #082132;
  box-shadow: 10px 10px 10px #082132;
  transition: all ease .3s;
  border: 2px solid black;
}
.ben-12{
  font-size: 1.4rem;
  background-color: #082132;
  border: 2px solid white;
  border-radius: 0px;
  padding: 0rem 2rem;
  -webkit-transition: all ease .3s;
  -o-transition: all ease .3s;
  transition: all ease .3s;
  border: 2px solid black;
}
.ben-12:hover{  color: rgb(1, 1, 1);
  background-color: transparent;
 
  -webkit-box-shadow: 10px 10px 10px #082132;
  box-shadow: 10px 10px 10px #082132;
  transition: all ease .3s;
  border: 2px solid black;}

.sub-btn{
  margin-top: 2rem;
  background-color: #082132;
  border: 2px solid white;
  font-size: 1rem;
  border-radius: 50px;
  padding: .4rem 2rem;
  
  -webkit-transition: all ease .3s;
  -o-transition: all ease .3s;

  color: white;
}
.sub-btn1{
  margin-top: 2rem;
  background-color: #082132;
  border: 2px solid white;
  font-size: 1rem;
  border-radius: 50px;
  padding: .4rem 2rem;
  
  -webkit-transition: all ease .3s;
  -o-transition: all ease .3s;

  color: white;
}

.blogcss{
  border: 1px solid rgb(0, 0, 0);
  border-radius: 5px;
  padding: 30px;
  margin-left: -2rem;
  margin-bottom: 1rem;
  color: black;
  /* background-color: #1171e5; */
  /* font-family: 'matter-regular','matter-light','matter-bold','matter-medium', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', */

}

.blogmodal-content{
  justify-content: center;
  /* background-color: rgb(239, 233, 223); */
  align-items: center;
  text-align: center;
  border-radius: 10px;
  margin: 2rem;

}

.testquestion{
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 10px;
  margin: 2rem;
}





.resourcespagestt{ 
  height: 40px;
  background: cornsilk;
  margin: 5px;
  border-radius: 7px;
}



.modal-fullscreen {
  width: 100vw;
  max-width: 100%;
  margin: 0;
}

.modal-fullscreen .modal-dialog {
  width: 100%;
  max-width: 100%;
  height: 100%;
  margin: 0;
}
.rssss{ 
  border: 1px solid red;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

.krish{ 
  display: -webkit-box; 
  display: -ms-flexbox; 
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  color: white;
  /* margin-bottom: -13rem; */
  

  /* border-radius: 10px; */
  -ms-flex-wrap: wrap-reverse;
      flex-wrap: wrap-reverse;
}
.ifrm{
  width: 1280px;
   height: 749px;
}

.adimgc{
  width:80%;
  height: 4rem;
  object-fit: contain;
}
/* .cards-containerq{
  background-color: blue;
} */
.cardq{
  width: 100%;
  height: 100%;
  border-radius: 10px;
  margin: 1rem 2rem;
  object-position: top;
  border: none;
  padding: 0rem 2rem;
  background-color: transparent;
}
#dropdown-basic{
 
  /* color: white; */
  border: none;
   background: transparent;
   
}



.fixed-element{
  position: fixed;
  z-index: 10;

  left: 0; /* Adjust as needed */
 
}
.naviBox.with-margin{
  top: 0;
}


.mnop{ 
  /* margin: 10px; */
  height: 30px;
  margin-right: 1rem;
  font-size: 1.5rem;
  color: white;
  /* background-color: red; */
  text-decoration: none;
  -webkit-transition: all ease .3s;
  -o-transition: all ease .3s;
  transition: all ease .3s;
}
.mno{ 
  /* margin: 10px; */
  height: 40px;
  margin-right: 1rem;
  font-size: 2.3rem;
  color: rgb(4, 34, 66);
  /* background-color: red; */
  text-decoration: none;
  -webkit-transition: all ease .3s;
  -o-transition: all ease .3s;
  transition: all ease .3s;
}
.mnopp{
  color: #000;
  text-decoration: none;
}
.mnop:hover{
  color: #9DBDDB;
  scale: 1.5;
}
.mno:hover{
  color: #0ea2ec;
  scale: 1.5;
}
.dropdown-menu.show { 
  /* background: #000; */
  background-color: #082132;
  color: black;
}

.ben-10{
  color: black;
  background-color: transparent;
  font-size: 1.4rem;
  border-radius: 50px;
  padding: 0rem 2rem;
  -webkit-transition: all ease .3s;
  -o-transition: all ease .3s;
  transition: all ease .3s;
  border: 2px solid black;
}

.ben-9{
  color: rgb(251, 251, 251);
  background-color: transparent;
  font-size: 1.4rem;
  border-radius: 50px;
  padding: 0rem 2rem;
  -webkit-transition: all ease .3s;
  -o-transition: all ease .3s;
  transition: all ease .3s;
  border: 2px solid rgb(7, 181, 249);
}
.ben-9:hover{
  background-color: rgb(7, 181, 249);
  color: #000;
  /* font-weight: 700; */
}

.btnblog{
  color: black;
  background-color:  transparent;
  font-size: .7rem;
  font-weight: bolder;
  border-radius: 50px;
  padding: .3rem 1rem;
  -webkit-transition: all ease .3s;
  -o-transition: all ease .3s;
  transition: all ease .3s;
  border: 2px solid #082132;
}
.btnblog:hover{
  background-color: #082132;
  color: white;
  /* font-weight: 700; */
}


.ben-11{
  color: black;
  background-color: transparent;
  font-size: 1.5rem;
  border-radius: 50px;
  padding: 0rem 2rem;
  -webkit-transition: all ease .3s;
  -o-transition: all ease .3s;
  transition: all ease .3s;
  border: 2px solid black;
}
.btns{
  color: white;
  background-color: #082132;
 
  font-size: 1rem;
  border-radius: 0px;
  padding: .7rem 1rem;
  -webkit-transition: all ease .3s;
  -o-transition: all ease .3s;
  transition: all ease .3s;
  border: 2px solid black;
}
.btns:hover{
  background-color: transparent;
border: 2px solid black;
-webkit-box-shadow: 10px 10px 10px#082132;
        box-shadow: 10px 10px 10px#082132;
color: black;
}
.btnm{
  font-size: 1rem;
  border-radius: 20px;
  padding: .7rem 1rem;
  -webkit-transition: all ease .3s;
  -o-transition: all ease .3s;
  color: white;
  transition: all ease .3s;
  background-color: #082132;
  border: 2px solid black;
}
.testcardflex{ 
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 10px;

} 
.btnm:hover{
  background-color: transparent;
  color: black;
border: 2px solid black;
-webkit-box-shadow: 10px 10px 10px#082132;
        box-shadow: 10px 10px 10px#082132;
}


.ben-11:hover{
  background-color: #082132;
  border: 2px solid white;
  -webkit-box-shadow: 10px 10px 10px #082132;
          box-shadow: 10px 10px 10px #082132;
  color: white;
}
.ben-10:hover{
  background-color: #082132;
  border: 2px solid white;
  -webkit-box-shadow: 10px 10px 10px #082132;
          box-shadow: 10px 10px 10px #082132;
  color: white;
}

.advs{
  color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  font-size: 3rem;
  padding: 1rem;
  margin-top: -1rem;

}

.advs>p{
  font-size: 1.5rem;
}


.il{
  font-size: 1.2rem;
}

.p{
  background-color: #000;
}
h1>span{
  font-size: 2rem;
  color: #2c84c2;
  -webkit-filter: drop-shadow(0 0 1rem rgb(4, 34, 66));
          filter: drop-shadow(0 0 1rem rgb(4, 34, 66));
} 

.j{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.card-contentq h2{
  font-size: .8rem;
  font-weight: bolder;
}
.card-contentq p{
  font-size: 1.2rem;

}
.cv{
  background-color: #b7d0ee;
}
.l{
  margin-bottom: 5rem;
}
.mb{
margin-top: 7rem;
}
.ll{
  font-size: 3.5rem;
}
.s{
  margin-left: 4rem;
}
.p{
  margin-top: 7rem;
}
.d{
  margin-right: 10rem;
}
.v{
  /* margin-top: -3rem; */
  /* margin-right: 8rem; */
  /* margin-top: 3rem; */
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  /* margin-top: -5rem;
  margin-bottom: -4rem; */

}
/* .text_item .x{
  margin-top: 3rem;
} */

.dv{
  margin-right: 13rem;
}
h1>span{
  color: #012641;
  font-size: 1rem;
  /* margin-top: 1rem;
   */
   /* padding-top: 3rem; */
  -webkit-filter: drop-shadow(0 0 1rem rgb(4, 42, 83));
          filter: drop-shadow(0 0 1rem rgb(4, 42, 83));
}
h3>span{
  color: #012641;
  font-size: 1.7rem;
  /* margin-top: 1rem;
   */
   /* padding-top: 3rem; */
  -webkit-filter: drop-shadow(0 0 1rem rgb(4, 42, 83));
          filter: drop-shadow(0 0 1rem rgb(4, 42, 83));
}
/* 
.card-containerq h2{
  font-size: .5rem;

} */

.about_c_ssxx h4{
  font-size: 2rem;

}
.about_upper{
  margin-bottom: 2rem;
  /* margin-top: -8rem; */
}
.ds{
  margin-top: 2rem;
  font-size: 1.5rem;
 
}



/* end of changes by me  */
* {
  margin: 0px;
  padding: 0px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
} 
.imgbuto{ 
  width: 400px;
}
.footer_logo img{
  width: 300px;
  height: auto;
  margin-top: 5rem;
  ;
}
.higw1{
  margin-top: 1rem;
  border-radius: 2px solid grey;
  padding: 0rem 1.5rem;
} 
.higw1>img{
  border-radius: 20px solid grey;
  border: 10px;
-o-object-position: top;
   object-position: top;
-webkit-box-shadow: 0px 10px 10px grey;
        box-shadow: 0px 10px 10px grey;
}
.border1{
  /* margin: 0rem 2rem; */
  padding: 0rem 1rem;
  /* margin-bottom: 4rem; */
  border-radius: 2px solid grey;
  /* margin-top: -2rem; */

  /* margin-left: -2rem; */
}
.border1>.text-item{

  margin-top: -3rem;
}
.blog-container1{
  /* background-color: #e5e5f7; */
/* opacity: 0.6; */
/* background-image:  repeating-radial-gradient( circle at 0 0, transparent 0, #e5e5f7 10px ), repeating-linear-gradient( #00bbffdf, #00bcff ); */
/* margin-left: 0%; */
/* margin-right: 0%; */
/* margin-top: 4rem; */
/* margin: 0px 0px; */
padding: 0rem 20rem;
/* width: 100%; */
margin-bottom: 3rem;

}

.slider {
  width: 200px;
  margin: auto;
  text-align: center;
}

.slide-container {
  overflow: hidden;
  width: 200px;
  height: 200px;
}

.slide {
  display: none;
}

.active {
  display: block;
}

img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.sm{
  margin-left: 4rem;
}
.footer_list li a{ 
  text-decoration: none;
  color: white;
  line-height: 0%;
}
.footer_list li a:hover{ 
 
  color: #1A509C;
}
.footer_list{
  line-height: 90%;

}
html,
body {
  font-family: Verdana, Geneva, sans-serif;
  /* font-size: 16px; */
  color: #000;
  overflow-x: hidden;
}


.centerCol {
  margin: 0 auto;
  padding: 0 10px;
  width: 100%;
  max-width: 1500px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: sticky !important;
  top: 0px;
  /* background-color: #0EBFBE; */

} 
.card_blog{
  width: 40vw;
}
.centerblog{ 

  margin: 0 auto;
  padding: .5rem .5rem;

  border-radius: 1px;
background-color: #efefeff1;
 
  /* margin-bottom: 2rem; */
}
.pz{
  background-color: rgb(110, 182, 246);

  /* margin: 0 1rem; */

  border-radius: 5px;

 
}

.img_card{ 
  width: 390px;
  height: 270px;
  /* padding: 10px; */
  margin-left: -40px;
  border-radius: 5px;

}

.blog_img{ 
  width: 200px;
  height: 200px;
  /* barder-radius: 5px 5px 0 0; */
  /* border: 1px solid ; */
}
.sbtn1{ 
  background: none;
  /* border-radius: 8px; */
  margin-left: .3rem;
  font-size: 1.5rem;
  background-color: white;
  border: none;
  
  color: black;
  border-radius: 5px;
  width: 40px;
}
.sbtn1:hover{ 
  background-color: #0B162A;
  color: white;
}

.centerCol h3{


  text-align: center;
  font-weight: bold;
  /* color: #1483cd; */
  margin: 10px;
}  
.centerCol p{

text-align: center;
/* color:rgb(60, 14, 43) ; */
font-weight: bold;
} 

 ul{
 list-style: none;
 margin-left: 20px;
}

.footer_list li:hover{
color: grey;

}
.cibox{ 
  width: 400px;
  height: 400px;
}
.footer_list li{ 
  margin: 10px;
}
.bgcontac{ 
  background-color: #D2ECF8;
  -webkit-filter: drop-shadow(0 0 1rem rgb(4, 42, 83));
          filter: drop-shadow(0 0 1rem rgb(4, 42, 83));
margin: 3rem 0rem;
  
}
.center1{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;

}
#question-txt{
  font-size: 1.3rem;
}
.ctexta{ 
  color: #000000;
  font-size: 12px;
  font-weight: 600;
  margin-left: 30px;
}
.border_colo{ 
  /* background: linear-gradient(white, white) padding-box,
              linear-gradient(to right, darkblue, darkorchid) border-box;
  border-radius: 4.5em;
  border: 5px solid transparent;   */
  /* border: 1px solid #0c0b3b; */
  border-radius: 5px;
  -webkit-filter: drop-shadow(0 0 1rem rgb(4, 42, 83));
          filter: drop-shadow(0 0 1rem rgb(4, 42, 83));

  /* box-shadow: 10px 10px rgb(211, 210, 210); */
  margin: 20px;

}
.marginpx{ 
  margin: 5px;
}
.about_c_ss{ 
  width: 500px;
  height: 400px;
}

.about_c_ssxx{ 
  width: 450px;

 
}
.displayflex{ 
  display: -webkit-box; 
  display: -ms-flexbox; 
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.displayflex1{ 
  display: -webkit-box; 
  display: -ms-flexbox; 
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.imgicons{ 
  width: 30px;
  height: 30px;
  margin: 5px;
}
.map{ 
  width: 400px;
  height: 400px;
}
.map1{ 
  width: 400px;
  height: 400px;
}
.card_flex1{ 
  display: -webkit-box; 
  display: -ms-flexbox; 
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.row_flex_card{ 
  display: -webkit-box; 
  display: -ms-flexbox; 
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
 
}
.red1{ 
  position: relative;
}
.more_btn{ 
  width: 295px;
  height: 47px;
  font-family: 'Source Code Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 50px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #0b1230;
  border-radius: 25px;
  color: #FFFFFF;
  
}
.ttrr12{
  /* font-size: smaller; */
    font-weight: 600;
    font-size: .9rem;
    color: rgb(56, 54, 54);
    /* text-align: center; */
}
.kjhg{ 
  background: #dedddb;
  font-size: x-large;
  font-weight: bolder;
  margin-bottom: 19px;
  padding: 10px;
  border-radius: 10px;
}
.center{
  text-align: center;
  
}

.rowflex{ 
  display: -webkit-box; 
  display: -ms-flexbox; 
  display: flex;
}

.icons{ 
  display: -webkit-box; 
  display: -ms-flexbox; 
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  margin: 20px;
}
.icons li{ 
  font-size: 40px;
  margin: 10px;
}
.icons li:hover{ 
  color: #1A509C;
  cursor: pointer;
 /* color: red; */
 /* padding: 5px; */


}
.u_head{ 
  display: -webkit-box; 
  display: -ms-flexbox; 
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.email1{
  margin-top: 25px;
}
.email1 p a{
 text-decoration: none;
 color: black;
 font-weight: bold;
}
.carouselbox{ 
  max-width: 1500px;
  height: 500px;
  /* z-index: -1000; */
}

/* ---------------------------------------------------------------- */


.moving_tag{ 
  color: #01051c;
  font-weight: bold;
}
.cardbody123{ 
  margin-top: 30px;
}

.headercol{
margin-bottom: 4rem;
}
/* #naviBox ========= */
.naviBox {
  width: 100%;
  height: 50px;
  padding: 11px;
   /* margin-bottom:-4rem; */
  background-color: #082132;
  color: white;
  /* z-index: -100; */

}

.bgc_s{ 
  /* background-image: linear-gradient( to bottom, #222023, #1e2c47 );   */
  
  background-color: #082132;

}
.navbar_h{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none !important;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  /* width: 97%;  */
  /* position: sticky !important; */
}
.navbar_h li a{
  text-decoration: none;
  color: #fff;
}

.navbar_h li {

}
.navbar_h li a:hover{
 color:  #9dbddb;
 padding: 5px;
 
}
.menu_btn{ 
  display: none;
}
.nomore{
  margin-top: -8rem;
  color: rgb(35, 33, 33);
}
.border_dot{
  border: 2px dotted white;
  margin: 2px;
}
.circle{ 
  width: 200px;
  height: 120px;
  border: 1px ;
  border-radius: 10%;
  margin: 10px auto;
 
  background-repeat: no-repeat;
  background-size: contain;

}


.media_card:hover{ 
  cursor: pointer;
  scale: 1.1;
  z-index: 5;
  transition: all .3s ease-in-out;
 }
 
 .super-flex{
  margin-top: 1rem;
  margin-bottom: 2rem;

 }



.c1{
  background-image: url(../src/images/family.jpg);

          filter: drop-shadow(10px  10px  5px #0404043c);
   /* border: 1px solid white; */
  }
.c2{
  background-image: url(../src/images/property.jpg);
  filter: drop-shadow(10px  10px  5px #0404043c);
  }
.c3{
  background-image: url(../src/images/mediation.jpg);
  filter: drop-shadow(10px  10px  5px #0404043c);

  }
.c4{
  background-image: url(../src/images/insolvency.png);
  filter: drop-shadow(10px  10px  5px #0404043c);
  position: top;

  }
.c5{
  background-image: url(../src/images/criminal.png);
  filter: drop-shadow(10px  10px  5px #0404043c);

  }
.c6{
  background-image: url(../src/images/cl.jpg);
  filter: drop-shadow(10px  10px  5px #0404043c);

  }
.bbb{ 
  text-align: center;
  color: white;
}
.black{ 
  color: black;
}
.c {
  margin-top: -1rem ;
}
.bbb1{ 
  text-align: center;
  color: black;
  font-weight: 500;
  margin-top: 2rem;
  font-size: 2.3rem;
  padding: 20px;

}
.b1{
  text-align: center;
  font-size: 3rem;
}
.b1>span{
  color: #1A509C;
  -webkit-filter: drop-shadow(0 0 1rem rgb(4, 42, 83));
          filter: drop-shadow(0 0 1rem rgb(4, 42, 83));


}


.bbb1>span {
  color: #1A509C;
  -webkit-filter: drop-shadow(0 0 1rem rgb(4, 42, 83));
          filter: drop-shadow(0 0 1rem rgb(4, 42, 83));

  font-size: 3rem;
}
.row_flex{ 
  display: -webkit-box; 
  display: -ms-flexbox; 
  display: flex;
 
  -webkit-box-pack: justify;
  gap: 20px;
      -ms-flex-pack: justify;
 
          /* justify-content: spa; */
}
.row_flex li{ 
  /* margin: 10px; */
  /* margin: 5px 10px; */
  height: 10px;
  width: 2rem;
  display: flex;
  
  /* font-size: 2rem; */
 
}

.row_flex li:hover{ 
  color: #1A509C;
  cursor: pointer;
 /* color: red; */
 /* padding: 5px; */
}


.card_head{
  /* text-align: center; */
  font-weight: 500;
  font-size: 1.2rem;
  color: #0a0a0a;
}
.card_head a{
  color: red;
}
.kmsxyz{
  margin-bottom: 3rem;
}
.sub_t{
  text-align: center;
  color: #f01cca;
}
.hov:hover{ 
  background-color: #051834;
  color: white;
  -webkit-box-shadow: 20px 20px 30px 25px rgba(2, 2, 2, 0.25);
          box-shadow: 20px 20px 30px 25px rgba(2, 2, 2, 0.25);
}
.hov{ 
  font-size: .8rem;
  border-radius: 10px;
background: rgb(238, 249, 253);
-webkit-box-shadow: 10px 10px 30px 10px rgba(79, 77, 77, 0.25);
        box-shadow: 10px 10px 30px 10px rgba(79, 77, 77, 0.25);
}
.rectangle{ 
  width: 300px ;
  height: 200px;
  background-color: #01051c;
}
.advocatecard{
  background-color: #000;
}
.ttray{ 
  margin-top: -4rem;
  /* border-radius: 10px; */
  /* background-color: #63A9EA; */
  /* margin-bottom: 6.5rem; */
 
}

.sub-btn1-none{
  background-color: transparent;
  color:#082132;
  
  font-weight: bolder;
  border-radius: none;
  border: none;
}
.sub-btn1-none:hover{
  color: rgb(1, 1, 1);
  background-color: transparent;
 
  -webkit-box-shadow: 10px 10px 10px #082132;
  box-shadow: 10px 10px 10px #082132;
  transition: all ease .3s;
  border: 2px solid black;
}
.about_cont{  

  color: white;
  background: #63A9EA;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-radius: 10px;
}
.ttray1{ 
  margin-top: 4rem;
  /* border-radius: 5px; */
  /* background: rgba(80, 184, 231, 0.58); */
  /* background-color: #0e75bf27; */
  background-color: url(../src/images/mediation.jpg);
  /* margin-top: 10px; */
  /* margin-top: 2rem; */
  /* margin-bottom: 2rem; */
  padding: 0rem 2rem;
 
}




.upper_header{ 
  display: -webkit-box; 
  display: -ms-flexbox; 
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: white;
  background-color: #082132;
}
.box_home{ 

  width: 400px; 
    height: 400px;
    margin-top: 2rem;
 
}
.box_hom{ 

  width: 400px;
  height: 350px;
  /* background-image: url(); */
  background-repeat: no-repeat;
  background-size: contain;
  /* filter: drop-shadow(0 1rem 1rem 2rem rgb(4, 42, 83)); */
  /* -webkit-filter: drop-shadow(2mm 8mm 5mm rgba(4, 42, 83, 0.577));
          filter: drop-shadow(2mm 8mm 5mm rgba(4, 42, 83, 0.577)); */
  margin-top: 2rem;
}


.bgvf{ 

  width: 600px;
  height: 300px;
  background-image: url(../src/images/im1.png);
  -webkit-filter: drop-shadow(0 0 1rem rgb(4, 42, 83));
          filter: drop-shadow(0 0 1rem rgb(4, 42, 83));
  background-repeat: no-repeat;
  background-size: contain;
}
.bgv{ 
  background-image: url(../src/images/ab2.png);
  background-position: right;
  -webkit-filter: drop-shadow(0 0 1rem rgb(4, 42, 83));
          filter: drop-shadow(0 0 1rem rgb(4, 42, 83));
  background-repeat: no-repeat;
  background-size: contain;
} 


.f_boxo{ 
  margin-top: 6rem;
max-width: 400px;

 
}

.bg_hyt img{ 
width: 450px;
height: 450px;
  /* background-image: url(../src/images/im5.png); */
  /* -webkit-filter: drop-shadow(0 0 1rem rgb(4, 42, 83));
          filter: drop-shadow(0 0 1rem rgb(4, 42, 83)); */
  background-repeat: no-repeat;
  background-size: contain;
  /* background-size: cover; */
} 
.icon_d_size{ 

  width: 40px;
  height: 40px;
}
.imgcus1{ 
  width: 300px ;
  height: 300px;
}
.img_card {
  width: 550px;
  height: 450px;
  margin-top: -3rem;
}
.rtyrev{ 
  display: -webkit-box; 
  display: -ms-flexbox; 
  display: flex;
  -ms-flex-wrap: wrap-reverse;
      flex-wrap: wrap-reverse;
      /* margin-top: 5rem; */
}

.newtxt{
  margin-top: 8rem;
}
.pd{
  /* padding: 2rem 2rem ; */
}
.row_dds1{ 
  display: -webkit-box; 
  display: -ms-flexbox; 
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -ms-flex-wrap:wrap;
      flex-wrap:wrap;
    
}
.mar{
  margin-bottom: -5rem;
}
.l{
  margin-top: 6rem;

}
.row_dds{ 
  display: -webkit-box; 
  display: -ms-flexbox; 
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -ms-flex-wrap: wrap-reverse;
      flex-wrap: wrap-reverse;
}


.para_cent { 
  
/* width: 10vw; */
width: 40vw;
 
}
.bg_img6{ 
  width: 650px;
  height: 500px;
  background-image: url(../src/images/img6.png);
  -webkit-filter: drop-shadow(0 0 1rem rgb(4, 42, 83));
          filter: drop-shadow(0 0 1rem rgb(4, 42, 83));
  background-repeat: no-repeat;
  background-size: contain;
}
.rflex{ 
  display:-webkit-box; 
  display:-ms-flexbox; 
  display:flex ;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  border: 1px solid red;

}
.Text_box>p{ 
  font-size: 1.2rem;
  color: rgb(42, 41, 41);
}
.Text_box>center{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 30px;
 
  
}
.Text_box h5{
  font-size: 2rem;
  font-weight:300;
}
/* .Text_box p{
  font-size: 1rem;
  font-weight: 100;
} */

.xxxx{ 
  display: -webkit-box; 
  display: -ms-flexbox; 
  display: flex;

}
.krowe{ 

  display: -webkit-box; 

  display: -ms-flexbox; 

  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.boxx{ 
  width: 400px;
  width: 400px;
  border: 2px solid green;
}
/* .row_dds1{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;

} */
.new{
  /* display: flex; */
  /* margin-right: 3rem; */
  margin-left: -20rem;

}
.bg_img7{ 
  width: 650px;
  height: 500px;
  background-image: url(../src/images/img7.png);
  -webkit-filter: drop-shadow(0 0 1rem rgb(4, 42, 83));
          filter: drop-shadow(0 0 1rem rgb(4, 42, 83));
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: -3rem;
}
/* .bg_im7{ 
  width: 650px;
  height: 500px; */
  /* background-image: url(../src/images/h6.png); */
  /* -webkit-filter: drop-shadow(0 0 1rem rgb(4, 42, 83));
          filter: drop-shadow(0 0 1rem rgb(4, 42, 83));
  background-repeat: no-repeat;
  background-size: contain;

} */

.row_dds{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: -4rem;
}
.row_dds button{
  margin-top: 2rem;
}

.card_body_tyu{ 
  /* height: 150px; */
  /* margin: 10px; */
  border-radius: 2px;
  margin-left: 3rem;
  margin-top: 2rem;

  /* box-shadow: 2px 2px 2px grey; */
 
}
.search_d{ 
  display: none;
}

.div_box{ 
  margin-top: 2rem;
  /* width: 500px;
  height: 400px; */
  /* margin-top: 3rem; */
}

.flex{ 
  display: -webkit-box; 
  display: -ms-flexbox; 
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -ms-flex-wrap: wrap-reverse;
      flex-wrap: wrap-reverse;
}
.flexr{ 
  display: -webkit-box; 
  display: -ms-flexbox; 
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -ms-flex-wrap: wrap-reverse;
      flex-wrap: wrap-reverse;
}

.super-text{
  margin-top: 5rem ;
}

.btn_blog{ 
  width: 150px;
}
.boxfit{ 
  width: 500px ;
  height: 400px;
}
/* 
.bx_home12 img { 
  width: 100%;

} */
.box_hom12{ 
  width: 650px;
  height: 500px;

 margin-left: -2rem;
  
  background-image: url(../src/images/media.png);
  -webkit-filter: drop-shadow(0 0 1rem rgb(4, 42, 83));
          filter: drop-shadow(0 0 1rem rgb(4, 42, 83));
  background-repeat: no-repeat;
  background-size: contain;
} 
.myyl{ 
  margin-top: 3rem;
}
.textxx>p{
  color: black;
  font-size: 1.6rem;
}
.textxx>h4{}


.box_hom2{
  width: 400px;
height: 300px;
margin-top: 1rem;
}
  /* font-size: 3rem; */
/* }


.box_hom2{ 
  width: 600px;
  height: 500px;
  margin-top: 3rem;
  background-image: url(../src/images/ksgroup.jpg);
  -webkit-filter: drop-shadow(0 0 1rem rgb(4, 42, 83));
          filter: drop-shadow(0 0 1rem rgb(4, 42, 83));
  background-repeat: no-repeat;
  background-size:cover; 
  border-radius: 10px;
} */
.ftflex{ 
  display: -webkit-box; 
  display: -ms-flexbox; 
  display: flex;
  -ms-flex-pack:distribute;
      justify-content:center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.box_hom21{ 
  /* width: 500px;
  height: 300px; */
  /* margin-top: 3rem; */
  /* background-image: url(../src/images/ab2.png); */
  /* -webkit-filter: drop-shadow(0 0 1rem rgb(4, 42, 83));
          filter: drop-shadow(0 0 1rem rgb(4, 42, 83)); */
  background-repeat: no-repeat;
  background-size:cover; 
  border-radius: 10px;
}
.box_homxx{ 
  width: 500px;
  height: 300px;
  margin-top: 3rem;
  -webkit-filter: drop-shadow(0 0 1rem rgb(4, 42, 83));
          filter: drop-shadow(0 0 1rem rgb(4, 42, 83));
  background-repeat: no-repeat;
  background-size: contain; 
  border-radius: 10px;
}
.textxx{ 

  margin-top: 20px;
}
.rupaliii{ 
  display: -webkit-box; 
  display: -ms-flexbox; 
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -ms-flex-wrap:wrap;
      flex-wrap:wrap;
}
.rupaliii1{ 
  display: -webkit-box; 
  display: -ms-flexbox; 
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap:wrap-reverse;
      flex-wrap:wrap-reverse;
}
.rupaliii1>.bgvf{
  margin-left: 10rem;
}
.box_home1{ 

  width: 350px;
  height: 350px;
  position: relative;

} 
.bgc_gtr{
  border-radius: 10px;
background: rgba(210, 236, 248, 0.58);
margin: auto;
}
.m_auto{ 
  margin: auto;
}
.boxdf{ 
 height: 100px; 
 border: 2px solid rgb(255, 255, 255);
 background: #012641;
color: white;
-webkit-backdrop-filter: blur(25px);
        backdrop-filter: blur(25px);
}
.text_item{
/* padding: 50px; */
margin-top: 6rem;
width: 40vw;
/* margin-bottom: 2rem; */


/* padding: 50px; */
}
.text_item>p{
  font-size: 3rem;

}
.text_ite{
  width: 45vw;
  margin-top: 2rem;
  /* margin-bottom: 2rem; */
}
.text_ite>center{
  margin-top: 2rem;
}
.text_ite h4{
  font-size: 2.5rem;
  font-weight: 400;
  color: rgb(7, 36, 66);
  -webkit-filter: drop-shadow(0 0 1rem rgb(4, 42, 83));
          filter: drop-shadow(0 0 1rem rgb(4, 42, 83));
  

  
}
.text_item h1{
  font-size: 2.5rem;
  font-weight: 400;
  color: rgb(54, 54, 54);
  
}
span{
  font-size: 2.2rem;
  color: #0e90bf;
}

.text_item p{
  font-size: 1.2rem;
  font-weight: 400;
  color: rgb(63, 61, 61);
  /* width: 40vw; */
  
}
.im2{ 
  width: 100px;
}

.kms{ 
  width: 200px;
  height: 200px;
  background-color: #f01cca;
  position: relative;
}
.kms1{ 
  width: 200px;
  height: 200px;
  background-color: #1cf02e;
  position: absolute;
 left:75px ;
 top: 100px;
}
.wid400px{ 
  height: 300px;
} 
.iconsrr{ 
 width: 45px;
 height: 45px;
}
.higw1{ 
  width: 300px;
  height: 250px;
  -o-object-fit: cover;
     object-fit: cover;
  /* display: flex; */
}
.higw2{ 
  width: 600px;
  height: 250px;
}
.higw3{ 
  width: 200px;
  height: 180px;
 
}
.row1{ 
  display: -webkit-box; 
  display: -ms-flexbox; 
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-bottom: 3rem;
  /* padding: 1rem 2rem; */
  /* background-color: #2887ed4e; */
}
/* .center{ 
  align-items: center;
} */

/* footer */
.footer_class{
  display: flex;
  justify-content: space-around;
}
.footer_class_group{
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.footer_body{ 
  background-color:#082132 ;
  padding: 1.5rem;
  /* background-image: linear-gradient( to bottom, #222023, #1e2c47 ); */
color: rgb(234, 229, 229);
}
.footer_body.border{
 margin: 10px;
}
.side_bar{ 
  list-style: none;
  /* background-color: #1A509C; */
}
.side_bar li a{ 
  text-decoration: none;
  color: #000;
}

.btn_search{ 
  color: white;
}

.red{ 
  border: 1px solid rgb(236, 0, 0);
  
}

.red2{ 
  border: 1px solid red;
} 
.none{ 
  display: none;
}
.rupl{ 
  display: -webkit-box; 
  display: -ms-flexbox; 
  display: flex;
  
}




.containerxx{
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  background: #012641;
  padding: 15px;
}
.containerxx ImageCard {
  width: 100%;
  display: block;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transition: all 100ms ease-out;
  -o-transition: all 100ms ease-out;
  transition: all 100ms ease-out;

}
.containerxx img:hover {
  -webkit-transform: scale(1.04);
      -ms-transform: scale(1.04);
          transform: scale(1.04);
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}
.mmru{ 
  display: none;
} 

.d-flex .form-control{
  font-size: .8rem;
  width: 20vw;

}
.home_btn_menu{ 
  /* width: 50px; */
  font-size:2rem;
}
.rrrrrr{
float: right;
}
.rewad{ 
  height: 100px;
  width: 100px;
  margin: auto;
  background-image: url(../src//images/rewards.png);
  background-position: center;
  background-repeat: no-repeat;
  /* -webkit-filter: drop-shadow(0 0 1rem rgb(4, 42, 83));
          filter: drop-shadow(0 0 1rem rgb(4, 42, 83)); */
  background-size: contain;
  -webkit-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}
.rewad:hover{
  scale: 6;
  z-index:10;
}
.a1{
  background-image: url(../src//images/a1.jpeg);
}
.a2{
  background-image: url(../src//images/a2.jpeg);
}
.a3{
  background-image: url(../src//images/a3.jpeg);
}
.a4{
  background-image: url(../src//images/a4.jpeg);
}
.a5{
  background-image: url(../src//images/a5.jpeg);
}
.a6{
  background-image: url(../src//images/a6.jpeg);
}
.a7{
  background-image: url(../src//images/a7.jpeg);
}
.a8{
  background-image: url(../src//images/a8.jpeg);
}
.a9{
  background-image: url(../src//images/a9.jpeg);
}
.a0{
  background-image: url(../src//images/a0.jpeg);
}

.fffht{ 
  display: -webkit-box; 
  display: -ms-flexbox; 
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
.media_class{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: -6rem;
 
          /* justify-content: space-around; */
}

.respad{
  margin: 3rem 2.5rem;
  padding: auto;
  user-select: none;
-moz-user-select: none;
-webkit-user-select: none;
-ms-user-select: none;
}
.bimg>img{
  width: 100%;
}
  .input_width{
    width: 100%;
    /* margin: 0rem -3rem; */
  }
  /* section{
    display: flex;
    flex-direction: column;
    
  } */


/* #Media Queries
================================================== */
@media only screen and (max-width: 1100px) {

  .navbar_h{
    display: none;
  }
  .menu_btn{ 
    display: block;
  }

}
.textxx>h4{
  font-size: 1.8rem;
}
.textxx>p{
  font-size: 1.2rem;
  margin-bottom: 3rem;
}


.cards-containerq {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 6px;
  color: #333;
  background: #c9e1f2c6;
  padding: 1rem;
  -webkit-box-shadow: 0 0 1rem #404b4f inset;
          box-shadow: 0 0 1rem #404b4f inset;
  overflow-x: auto;
  position: relative;
  
  &::-webkit-scrollbar {
    display: none;
  }
}

.cards-containerq .cards-advocate{
background-color: #ca0808;
}
.testcardimg{ 
  height: 50px;
  width: 50px ;
  margin-left: 100px;
  margin-top: 10px;
  border-radius: 50%;
}
.cards-containerqt {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 6px;
  color: #333;
  background: #151616c6;
  background-color: transparent;
  /* padding: 1rem 1rem; */
  -webkit-box-shadow: 0 0 1rem #cabbbb inset;
          box-shadow: 0 0 1rem #cabbbb inset;
  overflow-x: auto;
  position: relative;
  /* justify-content: center; */
  
  &::-webkit-scrollbar {
    display: none;
  }
}
.cardq img:hover{
  /* scale: 1.1; */
  z-index: 10;
  transition: all .3s ease-in-out;

}



.card-contentq {
  padding: 10px;
  background-color: #fff;
  text-align: center;
}

.card-contentq h2 {
  font-size: 1.2rem;
  margin-bottom: 5px;
  color: #333;
}

.card-contentq p {
  font-size: 1rem;
  color: #666;
}





.cardq:hover{
  scale: 1.1;
  z-index: 10;
  transition: all .3s ease-in-out;
}

.cardq {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 250px;
          flex: 1 0 250px;
  -webkit-box-shadow: 0 1px 1rem -4px #000;
          box-shadow: 0 1px 1rem -4px #000;
  background: #fff;
  /* margin: 1rem; */
  overflow: hidden;
  /* border-radius: 6px; */
  cursor: pointer;
  -webkit-transition: all 250ms ease-in-out;
  -o-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
  
  img {
    width: 300px;
    height: 250px;
    -o-object-fit: center;
       object-fit: center;
    /* margin: 10px; */
    margin-left: -3.7rem ;
    padding: 10px;
  }
  
  &:hover {
    -webkit-box-shadow: 0 4px 1rem -30x #000;
            box-shadow: 0 4px 1rem -30x #000;
    -webkit-transform: translateY(-3px);
        -ms-transform: translateY(-3px);
            transform: translateY(-3px);
  }
}
.cardqtt {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
  -webkit-box-shadow: 0 1px 1rem -4px #0f96eb;
          box-shadow: 0 1px 1rem -4px #0f96eb;
  background: #f7f7f7;
  margin: 1.5rem;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 6px;
  padding: 0rem 1rem;
  width: 30vw;
  /* width: 60vw; */
  cursor: pointer;
  -webkit-transition: all 250ms ease-in-out;
  -o-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
  
  img {
    
       display: none;
  }
  p{
    font-size: 1rem;
    /* width: 30rem; */
  }
  h2{
    font-size: 1rem;
    font-weight: bolder;
    align-items: center;
    justify-content: center;
    margin-top: .8rem;
  }
  
  &:hover {
    -webkit-box-shadow: 0 4px 1rem -4px #000;
            box-shadow: 0 4px 1rem -4px #000;
    -webkit-transform: translateY(-3px);
        -ms-transform: translateY(-3px);
            transform: translateY(-3px);
  }

}
/* .Accordion-Body{
  display: flex;
  justify-content: start;
} */
.card-content {
  padding: 1rem;
}

@media screen and (max-width: 725px) {
  .containerq {
    width: 100%
  }
  .textxx>h4{
    font-size: 1.6rem;
  }
  .textxx>p{
    font-size: 1rem;

  }
  
  .cards-containerq {
    padding: .5rem;
  }
  
  .cardq {
    margin: .5rem;
  }
  .about_font{
    font-size: .7rem;
  }
  .text_item h1{
    font-size: 1.5rem;


  }
  .text_item{
    width: 100%;
  }
  .flex{
    display: flex;
    flex-direction: row;
    /* flex-direction: column; */
  }
  .kmsxyz{

    display: flex;
    flex-direction: row;
    /* display: flex; */
    /* flex-direction: column; */
  }

}




@media only screen and (max-width: 510px)  {
  .logo11{
    display: none;
  }

  .sub-btn{
  font-size: .8rem;
  padding: 0rem .5rem;
  /* margin-right: 2rem; */
  }
  .sub-btn1{
  font-size: 1rem;
  margin-right: 2rem;
  }
  *{
    padding: 0rem 2rem;
  }

  .news_letter p{
    font-size: .9rem;
  

  }
 
.about-margin{
  margin-top: -1rem;
  margin-left: -3rem;
}
.certificate-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-bottom: 1rem;
  margin-left: -9rem;
}
.v{
  margin-top: -8rem;
  /* display: none; */


}
.containerg{
  display: flex;
  flex-direction: column;
  background: #e6eef3;
  padding: 1rem 0rem;
  border-radius: 10px;
}


.test_button{
  margin-top: -12rem;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  /* margin-left: -3rem; */
  margin-bottom: 1rem;

 
}
.option-container{
margin-left: -3rem;

}



.score_table{
  flex-direction: column;
  display: flex;
  font-size: 1rem;
  font-weight: bolder;
  margin-bottom: 1rem;  
 gap: 10px;
  
}
  .cardqtt{
    flex: 1 0 auto;
    width: 21rem;
  }
  .ifrm{
    width: 390px;
     height: 2rem;
  }
  
 
  .super-flex{
    display: flex;
    /* padding:0rem 3rem ; */
     flex-direction: row;
     row-gap: -5rem;
    margin-left: -2rem;
  }

  .center  {
    /* font-size: .8rem; */
  }
 
  .icons{
    margin-left: -4rem;

  }
  .f_col{
    /* margin-bottom: -2rem; */
  }
  .footer_body{
    /* margin-bottom: -2rem; */
  }
  .media_card{
   
     margin: 0rem -4rem ;
     /* margin-bottom: -2rem; */

  }
  .mode-card{
    margin-bottom: -2rem;
  }
  .circle{
    margin-bottom: -3.4rem;


  }
  .ben-11{
  font-size: 1.2rem;
  padding: 1rem 1rem;
  }
  .rewad:hover{
    scale: 3;
    z-index:10;
  }
  .ben-12{
    font-size: 1.2rem;
    padding: 0.3rem 1rem;
  }
  .ben-10{
    padding: 0.2rem 1rem;
    font-size: 1.6rem;
  }
  .btns{
    font-size: 1rem;
    padding: .3rem 1rem;
  }
  .textxx{
    margin-top: -1rem;
  }
  .bimg>img{
    min-width: 100%;
  }
  .higw2{
    margin-top: 5rem;
    margin-bottom: -2rem;
  }
  .bx_home12{
    margin-bottom: -18rem;
  }
  .mera{
    margin-top: -2rem;
  }
  .ds{
    padding: 0rem 2rem;
    margin-left: -1rem;
    margin-top: 0rem;
    ;
  }
  .textxx>p{
    color: black;
    font-size: 1.3rem;
   
  }

  .textxx>h4{
    font-size: 1.5rem;
  }


  .displayflex1{
    /* margin-bottom: 2.5rem; */

  }
  /* .pz{
    display: flex;
    flex-direction: column;
  }
  .pzz{

    display: flex;
    flex-direction: column;
  } */
  .box_hom2{
    width: 400px;
  height: 300px;
  }
  .btnm{
    font-size: 1.2rem;
    padding:  .4rem 1rem;
  }
  .nnn{
    /* margin-left: 1rem; */
    /* gap: 1px; */
    /* margin-top: -1rem; */
    margin-top: 1rem;
    margin-left: .5rem;
    /* margin-bottom: 1rem; */
  }
  .new{
  
    margin-left: 0rem;
   
    width: 100%;
    padding: 0rem 2rem;
  }
  .box_home12{
    width: 400px;
    height: 300px;
    margin-top: 3rem;
  }
  .fffht{
    margin-top: -6rem;
    /* margin-left: -2rem; */
    padding: 0rem 1rem;
 

  }
  .flexxx{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 1rem;
    /* margin-top: 3rem; */

  }
  .flex{
    margin-bottom: 5rem;
  }
 
  .mar{
    margin-top: -6rem;
  }
  .bg_hyt{ 
    width: 400px;
    height: 300px;
    margin-left: 3rem;
  }
 .ma{
  margin-bottom: -2rem;
 }
  .para_cent{
    width: 100%;
    margin-bottom: 1rem;
  }
  .bg_im7{
    width: 500px;
  height: 400px;
  /* margin-top: -5rem; */
  margin-bottom: -10rem;

  }
  .n{
    margin-bottom: 3rem;
  }
  .ll{
    font-size: 2rem;
    margin-top: 1rem;
  }
  
  span{
    font-size: 2rem;
    color: #0e90bf;
  }
  .bbb1>span{
    font-size: 1.5rem;
  }
  .bbb1{   font-size: 1.5rem;
  }

  
  .fffht>.box_hom12{
    /* margin-right:5rem ; */
    margin-top: 8rem;
    width: 500px;
    height: 400px;
    /* margin-bottom: 1rem; */
  }
  .text_item{
    margin-top: -.6rem;
    /* margin-left: 1rem; */
    /* margin-right: 1rem; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
  }
  .home_images{
    width: 100%;
  }
  .text_item h5{
    font-size: 1.5rem;
  }
  .text_item h1 b{
    font-size: 1.4rem;
  }
  .navbar_h{
    display: none;
  }
  .Text_box>center{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .menu_btn{ 
    display: block;
  }
  .kmssa{ 
    display: none;
  }
  .btn_search{ 
    display: none;
  }
  .text_item{
    width: 100%;
   
  }
  .text_item h1{
    font-size: 1.7em;
  
  }

  .div_box{
    /* width: 100% */
    margin: 0;
  }
  
  #logoimg{ 
    border: 1px solid red;
    width: 200px;
  
  }
  .white{
    color: white;
  }
  .icon_h{ 
    display: none;
  }
  .media_card{ 
    margin-top: 10px;
    padding-bottom: 3rem;
    margin-left: 70px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /* flex-direction: column; */
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }
  .icons_flex{ 
    display: -webkit-box; 
    display: -ms-flexbox; 
    display: flex;
    margin-top: 100px;
    margin-left: -20px;
  
  }
  .none{ 
    display: block;
  }
  .imgmargin{
    /* margin-top: -6rem; */
  }
  .fffht{ 
    -webkit-box-orient: vertical; 
    -webkit-box-direction: normal; 
        -ms-flex-direction: column; 
            flex-direction: column;
            padding: 0rem 2rem;
    /* margin: -4rem 2rem; */
    /* margin-top: -9rem; */
    /* margin: 0rem 1rem; */
  }
  .search_b{ 
    width: 200px;
  }
  .paraexp{ 
    width: 310px;
  }
  .mmru{ 
    display: block;
  }
  .rcarouselbox{ 
    max-width: 1500px;
    height: 200px;
  }
  
  .bg_img7{ 
  
    height: 350px;
  }
.form_data .form_heading>h1{
  font-size: 1.2rem;
}
  .resource-link{
    font-size: .9rem;
    /* margin-bottom: -.01px; */
    /* line-height: 20px; */
    /* line-height-step: -2px; */
  }
  .box_home{ 
    /* height: 360px; */
    /* margin-right: rem; */

  }
  .box_hom{ 
    height: 300px;
    width: 370px;
  }
  .bg_img6{ 
    height: 315px;
  }
  .about_font{
    font-size: 3rem;
  }
  .blogcss h3{
    font-size: 1rem;
  }
  .legal{
    margin-right: 7rem;
    margin-bottom: -6rem;
  }

  .profile-card{
    background-color: #000;
  }

.containerqq {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 500px;
  width: 800px;
  background-color: white;
  border-radius: 15px;
  /* box-shadow: 2px 2px 10px 1px rgb(102, 3, 102); */
  box-shadow: 2px 2px 10px 1px #333;
  overflow: hidden;
  padding: 10px;
  position: relative;
  margin: auto;
}

.heading-txt{
  color:white;
  /* color:#276272; */
  font-size: 30px;
  font-weight: bold;
  margin: 10px;
  text-align: center;  
  text-transform: uppercase;
}
.test_button{
  margin-top: -12rem;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  /* margin-left: -3rem; */
  margin-bottom: 1rem;

 
}



.containerg p{
  display: flex;
  justify-content: center;

}
.containerg{
  display: flex;
  flex-direction: column;
  background: #8bcaf16e;
  padding: 1rem 0rem;
  border-radius: 10px;
}

.question{
  margin:8px;
  /* box-shadow: 0px 0px 10px 1px #a2a3a3; */
  border-radius: 5px;
  padding:5px;
  font-size: large;
  font-weight: 600;
  font-family: 'Times New Roman', Times, serif;
  min-width: 100%;
  min-height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#question-number{
  margin-right: 5px;
}
.option-container{
  display: flex;
  flex-direction: column;
  margin:6px 4px;    
  width: 100%;
  padding: 10px;
 
  height: 400px;
}
/* .option-container input{
  visibility: hidden;    
} */
.option-btn{
  box-shadow: 0px 0px 4px 1px rgb(128, 123, 123);
  padding: 5px;
  border-radius: 3px;
  border:none;
  outline: none;
  transition: 0.3s;
  margin: 5px;
  min-height: 30px;
  background-color: white;
}

.checked{
  background-color: rgb(179, 83, 179);
  color:white;
  box-shadow: 3px 3px 4px 1px rgb(78, 69, 78);
}
.option-btn:hover{
  background-color: rgb(212, 118, 212);
  color:white;
}
#next-button{
  padding:10px;
  border:none;
  background-color: purple;
  background-color: #276272;
  color:white;
  border-radius: 5px;
  box-shadow: 3px 3px 2px 1px rgb(127, 109, 109);
  font-size: larger;
  font-weight: bold;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  transition: 0.2s;
  width:100px;
  position: absolute;
  bottom: 15px;
}
#next1-button{
  padding:10px;
  border:none;
  background-color: purple;
  background-color: #276272;
  color:white;
  border-radius: 5px;
  box-shadow: 3px 3px 2px 1px rgb(127, 109, 109);
  font-size: larger;
  font-weight: bold;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  transition: 0.2s;
  width:100px;
  position: absolute;
  bottom: 15px;
}
#next-button:active{
  box-shadow:none;    
}  
.testcardflex{ 
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 10px;

} 
.white{ 
  color: white;
  text-decoration: none;
  font-weight: 700;
}
.show-score{
  position: absolute;
  top:30%;
  font-size: 40px;
  text-align: center;  
} 
.login-test{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.lh{
  font-size: 1.1rem;
}
.footer{
  display: none;
}
.footer_class{
  display: flex;
  flex-direction: row;
}
.footer_class p{
  font-size: .7rem;
  margin-bottom: -.1px;
 
}
.footer_class_group{
  display: flex;
  flex-direction: column;
  /* line-height: -1rem; */
}

.footer_class_group a{
  font-size: .7rem;
  text-decoration-line: underline;
  /* line-height: -1rem; */
  margin-bottom: -.1px;
}
.footer_class_group h4{
  font-size: 1.1rem;
}

.footer_quick{
  justify-content: center;

  /* margin-bottom: -.1px; */
  align-items: flex-start;

}
.news_input{
  display: flex;
  margin-top: -1rem;
  margin-bottom: 1rem;
}
.blog-container1{
  /* background-color: #e5e5f7; */
/* opacity: 0.6; */
/* background-image:  repeating-radial-gradient( circle at 0 0, transparent 0, #e5e5f7 10px ), repeating-linear-gradient( #00bbffdf, #00bcff ); */
/* margin-top: 4rem; */
margin-bottom: 3.5rem;
padding: 0rem 2rem;
}

.footer_logo{
  display: none;
}
.inpsearch{ 
  width: 240px;
  /* height: 30px; */
  font-size: .8rem;
  /* width: 300%; */
  border-radius: 3px;
  padding: 0rem 1rem;
  border:none;
  outline: none;
  transition: 0.3s;
  /* margin: 5px; */
  /* padding: 5px; */
  min-height: 30px;
  /* padding: 0rem .5rem; */
}
.about_upper{
  margin-bottom: 2rem;
  /* margin-top: -8rem; */
}
.super-text{
  margin-top: 0rem;
  margin-bottom: -3rem;
}
.newtxt{
  margin-top: rem;
  margin-bottom: 2rem;
}
.text_item p{
  font-size: 1rem;
  font-weight: 400;
  color: rgb(63, 61, 61);
  /* width: 40vw; */
  
}
.test-titles h1{
  font-size: 1.1rem;
}
.blogmodal-content{
  margin: 2rem  .3rem;
  

}


.krish{ 
  display: -webkit-box; 
  display: -ms-flexbox; 
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  color: white;
  margin-bottom: -13rem;
  

  /* border-radius: 10px; */
  -ms-flex-wrap: wrap-reverse;
      flex-wrap: wrap-reverse;
}
.mnop{
  display: none;
}
}